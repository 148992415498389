<template>
  <widget-wrapper
    title="Riesgo de cartera"
    widget-id="widget-portfolio-risk"
    widget-name="Riesgo de cartera"
    :has-help="true"
    :help-url="url_iframe"
    :help-title="help_tittle"
    :has-alternate-action="true"
    alternate-action-icon="launch"
    :alternate-action-url="portfolioRedirectUrl"
    theme="dark"
    @close-widget="_closeWidget"
    @show-help="_sendDataToIframe"
  >
    <div v-if="loading" class="widget-loading">
      <div class="spinner"></div>
    </div>
    
    <div v-else class="portfolio-risk-content">
      <!-- Portfolio Selector -->
      <div class="portfolio-selector-container">
        <select
          v-model="value_selected"
          @change="_handlePortfolioChange"
          class="modern-select"
        >
          <option v-if="!value_selected" value="" disabled>Selecciona cartera</option>
          <option 
            v-for="portfolio in portfolio_list" 
            :key="portfolio.name"
            :value="portfolio.name"
          >
            {{ portfolio.value }}
          </option>
        </select>
      </div>
      
      <!-- Risk Visualization -->
      <div class="risk-visualization">
        <!-- Chart Container -->
        <div class="chart-container" ref="chartContainer">
          <div :id="chartId" style="height: 100%; width: 100%;"></div>
        </div>
        
        <!-- Risk Tip Text -->
        <div class="risk-tip" :style="{color: risk_tip_color}">{{ risk_tip }}</div>
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import GetPortfolios from "@/components/codeComponents/getPortfolios.js";
import APICaller from "@/components/codeComponents/APICaller.js";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";
import Highcharts from "highcharts";
import { mapGetters } from "vuex";

export default {
  mixins: [APICaller, GetPortfolios],
  name: "PortfolioRisk",
  components: {
    WidgetWrapper
  },
  data() {
    return {
      value_selected: "",
      portfolio_list: [],
      loading: true,
      risk_tip: "",
      risk_tip_color: "#FFFFFF",
      series: [
        {
          name: "Riesgo alcista",
          data: [],
          color: "#00add8",
        },
        {
          name: "Riesgo bajista",
          data: [],
          color: "#444242",
        },
      ],
      widget_name: { id: "widget-portfolio-risk", name: "Riesgo de cartera" },
      url_iframe: "https://player.vimeo.com/video/187303991?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre riesgo de cartera",
      chartInstance: null,
      _resizeHandler: null,
      chartId: 'highchart-content-risk-' + Math.random().toString(36).substring(7)
    };
  },
  computed: {
    ...mapGetters(["is_mobile_device", "_g_UserFeatures", "_g_User"]),
    portfolioRedirectUrl() {
      return this.value_selected ? `/app/portfolio/${this.value_selected}/risk` : '/app/portfolio';
    }
  },
  mounted() {
    this._initializeData(true);
  },
  beforeDestroy() {
    // Clean up chart instance
    if (this.chartInstance) {
      try {
        this.chartInstance.destroy();
      } catch (e) {
        console.error("Error destroying chart:", e);
      }
      this.chartInstance = null;
    }
    
    // Clean up resize listener
    if (this._resizeHandler) {
      window.removeEventListener('resize', this._resizeHandler);
      this._resizeHandler = null;
    }
  },
  methods: {
    async _initializeData(isInitialLoad = false) {
      
      this.loading = true;
      
      try {
        // Fetch portfolios only on initial load or if list is empty
        if (isInitialLoad || !this.portfolio_list || this.portfolio_list.length === 0) {
          
          this.portfolio_list = [];
          await this._getPortfolios();
          
        }

        // Select default portfolio
        if (isInitialLoad) {
          
          await this._defaultPortfolio();
          
        }

        // Fetch portfolio risk data
        if (this.value_selected) {
          
          await this._getPorfolioRisk();
          await this._getRiskTip();
          
        }

      } catch (error) {
        console.error("INIT: Initialization failed:", error);
        this.risk_tip = "Error cargando datos de riesgo de cartera";
      } finally {
        
        this.loading = false;
        this.$nextTick(() => {
          
          this._renderChart();
        });
      }
    },
    
    _sendDataToIframe() {
      this.$emit('show-help', {
        url: this.url_iframe,
        title: this.help_tittle
      });
    },

    _getPortfolios() {
      return new Promise((resolve, reject) => {
        
        
        const success = (response) => {
          if (response.data && Array.isArray(response.data) && response.data.length > 0) {
            this.portfolio_list = response.data.map(p => ({
              name: p.idc,
              value: p.name
            }));
            
            resolve(this.portfolio_list);
          } else {
            console.warn("GET_PORTFOLIOS: Empty or invalid response");
            this.portfolio_list = [];
            resolve([]);
          }
        };

        const failure = (error) => {
          console.error("GET_PORTFOLIOS: Error fetching portfolios:", error);
          this.portfolio_list = [];
          reject(error);
        };

        const url = "/api/v1/portfolio/list";
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler("ERR-PORTFOLIO", "Error recuperando cartera.");
        const apiCallConfig = { loadingOverlay: false };

        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      });
    },

    _defaultPortfolio() {
      return new Promise((resolve) => {
        // Check if a valid portfolio is already selected
        if (this.value_selected && this.portfolio_list.some(p => p.name === this.value_selected)) {
          
          resolve();
          return;
        }

        
        const success = (response) => {
          let defaultSet = false;
          
          // Check if API returned a default ID ('idc')
          if (response.data && response.data.idc) {
            const defaultIdc = response.data.idc;
            // Check if the default ID exists in the fetched portfolio list
            if (this.portfolio_list.some(p => p.name === defaultIdc)) {
              this.value_selected = defaultIdc;
              
              defaultSet = true;
            } else {
              console.warn(`DEFAULT: Default ID ${defaultIdc} received but not found in portfolio list.`);
            }
          } else {
            console.warn('DEFAULT: No default preference ID received from API.');
          }

          // Fallback: If no default was set AND the portfolio list is not empty
          if (!defaultSet && this.portfolio_list.length > 0) {
            this.value_selected = this.portfolio_list[0].name;
            
          } else if (!defaultSet && this.portfolio_list.length === 0) {
            // Ensure value_selected is reset if no portfolios exist
            this.value_selected = "";
            
          }
          
          resolve();
        };

        const failure = (error) => {
          console.error("DEFAULT: API Error fetching preferences:", error);
          // Fallback on error: If nothing is selected and list has items, select the first one
          if (!this.value_selected && this.portfolio_list.length > 0) {
            this.value_selected = this.portfolio_list[0].name;
            
          }
          
          resolve();
        };

        const url = "/api/v1/portfolio-invest-preferences";
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler("157", "Error recuperando preferencias de inversión.");
        const apiCallConfig = { loadingOverlay: false };
        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      });
    },

    _getPorfolioRisk() {
      return new Promise((resolve, reject) => {
        const currentPortfolioId = this.value_selected;
        

        // Handle case where no portfolio is selected
        if (!currentPortfolioId) {
          console.warn('GET_RISK: No portfolio selected, cannot fetch data.');
          this.series[0].data = [];
          this.series[1].data = [];
          resolve();
          return;
        }

        const success = (response) => {
          
          
          try {
            if (response.data && Array.isArray(response.data) && response.data.length >= 2) {
              // Reset series data
              this.series[0].data = [];
              this.series[1].data = [];
              
              // Extract risk values and update series names
              if (response.data[0] && response.data[0].data && response.data[0].data[0]) {
                const upRiskValue = response.data[0].data[0].y;
                this.series[0].name = `Riesgo alcista ${upRiskValue}`;
                this.series[0].data.push(upRiskValue);
              }
              
              if (response.data[1] && response.data[1].data && response.data[1].data[0]) {
                const downRiskValue = response.data[1].data[0].y;
                this.series[1].name = `Riesgo bajista ${downRiskValue}`;
                this.series[1].data.push(downRiskValue);
              }
              
              
            } else {
              console.warn(`GET_RISK: Invalid data structure in response for ${currentPortfolioId}`);
              this.series[0].data = [];
              this.series[1].data = [];
            }
          } catch (error) {
            console.error(`GET_RISK: Error processing data for ${currentPortfolioId}:`, error);
            this.series[0].data = [];
            this.series[1].data = [];
          }
          
          resolve();
        };

        const failure = (error) => {
          console.error(`GET_RISK: API Error for ${currentPortfolioId}:`, error);
          this.series[0].data = [];
          this.series[1].data = [];
          this.risk_tip = "No se pudo cargar el riesgo de la cartera";
          reject(error);
        };

        const url = `/api/v1/portfolio/get-risk/${currentPortfolioId}`;
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler("156", "Error recuperando riesgo de cartera.");
        const apiCallConfig = { loadingOverlay: false };
        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      });
    },

    _getRiskTip() {
      return new Promise((resolve) => {
        const currentPortfolioId = this.value_selected;
        

        // Handle case where no portfolio is selected
        if (!currentPortfolioId) {
          console.warn('GET_TIP: No portfolio selected, cannot fetch tip.');
          this.risk_tip = "";
          this.risk_tip_color = "#FFFFFF";
          resolve();
          return;
        }

        const success = (response) => {
          
          
          if (response.data && response.data.message) {
            this.risk_tip = response.data.message;
            this.risk_tip_color = response.data.color || "#FFFFFF";
            
          } else {
            console.warn(`GET_TIP: Invalid response structure for ${currentPortfolioId}`);
            this.risk_tip = "Información de riesgo no disponible";
            this.risk_tip_color = "#FFFFFF";
          }
          
          resolve();
        };

        const failure = (error) => {
          console.error(`GET_TIP: API Error for ${currentPortfolioId}:`, error);
          this.risk_tip = "No se pudo cargar la información de riesgo.";
          this.risk_tip_color = "#FFFFFF";
          resolve(); // Resolve even on error to continue initialization
        };

        const url = `/api/v1/widgets/get-risk-tip/${currentPortfolioId}`;
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler("158", "Error recuperando perfil de riesgo.");
        const apiCallConfig = { loadingOverlay: false };
        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      });
    },

    _renderChart() {
      // Get the target DOM element
      const chartElement = document.getElementById(this.chartId);
      if (!chartElement) {
        console.error(`RENDER: Chart container element #${this.chartId} not found in DOM.`);
        return;
      }

      // Destroy previous instance if it exists
      if (this.chartInstance) {
        
        try { this.chartInstance.destroy(); } catch(e) { /* Ignore errors on destroy */ }
        this.chartInstance = null;
      }

      try {
        
        
        // Calculate y-axis max value based on series data or default to 8 if no data
        const maxData = Math.max(
          this.series[0].data && this.series[0].data.length > 0 ? this.series[0].data[0] : 0,
          this.series[1].data && this.series[1].data.length > 0 ? this.series[1].data[0] : 0,
          8 // Minimum value to ensure the plotLine at 6 is visible
        );
        
        // --- Highcharts Configuration ---
        this.chartInstance = Highcharts.chart(this.chartId, {
          chart: {
            type: "column",
            backgroundColor: 'transparent',
            style: {
              fontFamily: '"Inter", "Helvetica Neue", "Helvetica", "Arial", sans-serif'
            },
            height: null,
            spacingTop: 5,
            spacingBottom: 10,
            spacingLeft: 10,
            spacingRight: 10
          },
          title: {
            text: null
          },
          subtitle: {
            text: "Eurekers",
            style: {
              color: '#BFC2CD'
            }
          },
          xAxis: {
            categories: [""],
            crosshair: true,
            labels: {
              style: {
                color: '#BFC2CD'
              }
            },
            lineColor: 'rgba(255, 255, 255, 0.1)'
          },
          yAxis: {
            min: 0,
            max: maxData * 1.2, // Add 20% headroom above the max value
            title: {
              text: "Riesgo (%)",
              style: {
                color: '#BFC2CD'
              }
            },
            labels: {
              style: {
                color: '#BFC2CD'
              }
            },
            gridLineColor: 'rgba(255, 255, 255, 0.1)',
            plotLines: [
              {
                value: 6,
                color: "#57c6f9",
                dashStyle: "shortdash",
                width: 2,
                label: {
                  text: "6 %",
                  style: {
                    color: '#BFC2CD'
                  }
                },
              },
            ],
          },
          tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
            footerFormat: "</table>",
            shared: true,
            useHTML: true,
            backgroundColor: 'rgba(32, 32, 43, 0.9)',
            borderColor: 'rgba(255, 255, 255, 0.2)',
            style: {
              color: '#FFFFFF'
            }
          },
          legend: {
            itemStyle: {
              color: '#BFC2CD'
            },
            itemHoverStyle: {
              color: '#FFFFFF'
            }
          },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0,
            },
            series: {
              animation: false
            }
          },
          credits: {
            enabled: false
          },
          series: this.series,
        });

        // Set up resize handler with debouncing
        if (!this._resizeHandler) {
          let resizeTimeout;
          this._resizeHandler = () => {
            if (resizeTimeout) {
              clearTimeout(resizeTimeout);
            }
            
            resizeTimeout = setTimeout(() => {
              if (this.chartInstance) {
                const container = this.$refs.chartContainer;
                const containerWidth = container ? container.offsetWidth : 0;
                const containerHeight = container ? container.offsetHeight : 0;
                
                
                
                if (containerWidth > 0 && containerHeight > 0) {
                  
                  this.chartInstance.setSize(containerWidth, containerHeight, false);
                }
              }
            }, 300);
          };
          
          window.addEventListener('resize', this._resizeHandler);
          
        }
        
        // Initial one-time sizing with delay
        setTimeout(() => {
          if (this.chartInstance) {
            const container = this.$refs.chartContainer;
            if (container && container.offsetWidth > 0 && container.offsetHeight > 0) {
              
              this.chartInstance.setSize(container.offsetWidth, container.offsetHeight, false);
            }
          }
        }, 250);

      } catch (error) {
        console.error(`RENDER: Error creating Highcharts instance #${this.chartId}:`, error);
        if (chartElement) {
          chartElement.innerHTML = `<div style='color: red; padding: 20px; text-align: center;'>Error rendering chart.</div>`;
        }
      }
    },

    _handlePortfolioChange() {
      
      this.loading = true;
      
      // Clear existing data
      this.series[0].data = [];
      this.series[1].data = [];
      this.risk_tip = "";

      // Update chart to show loading state
      if(this.chartInstance) {
        this.chartInstance.update({
          series: [
            { name: 'Cargando...', data: [], color: '#00add8' },
            { name: 'Cargando...', data: [], color: '#444242' }
          ]
        }, true, false);
      }

      // Fetch new data
      Promise.all([
        this._getPorfolioRisk(),
        this._getRiskTip()
      ])
      .then(() => {
        
      })
      .catch((error) => {
        console.error("CHANGE: Failed to fetch risk data:", error);
      })
      .finally(() => {
        this.loading = false;
        this.$nextTick(() => {
          
          this._renderChart();
        });
      });
    },

    _closeWidget() {
      this.$emit("close-widget", {
        id: this.widget_name.id,
        name: this.widget_name.name
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

/* Loading Spinner Styles */
.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-background, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

/* Main Content Area Styling */
.portfolio-risk-content {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  gap: 16px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: var(--color-surface, #20202B);
  position: relative;
  box-sizing: border-box;

  @media (max-width: 767px) {
    padding: 12px 16px;
  }
}

/* Portfolio Selector Styling */
.portfolio-selector-container {
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.1));
  flex-shrink: 0;
  margin-bottom: 8px;

  .modern-select {
    width: 100%;
    padding: 10px 30px 10px 12px;
    border: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
    border-radius: 6px;
    background-color: var(--color-surface, #20202B);
    color: var(--color-text-primary, #FFFFFF);
    font-size: 14px;
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M2.5 4L6 7.5L9.5 4' stroke='%23BFC2CD' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 12px;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;

    &:focus {
      outline: none;
      border-color: var(--primary, #00aedd);
      box-shadow: 0 0 0 1px var(--primary, #00aedd);
    }

    &:hover {
      border-color: var(--primary, #00aedd);
    }

    option {
      background-color: var(--color-background, #0F0F1A);
      color: var(--color-text-primary, #FFFFFF);
      padding: 8px 12px;
    }

    option[disabled] {
      color: var(--color-text-secondary, #BFC2CD);
    }
  }
}

/* Risk Visualization Section */
.risk-visualization {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: hidden;
}

/* Chart Container */
.chart-container {
  flex: 1;
  min-height: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

/* Risk Tip Styling */
.risk-tip {
  font-size: 13px;
  margin-top: 10px;
  line-height: 1.4;
  padding: 8px;
  text-align: center;
  font-weight: 500;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}

/* Deep selector for Highcharts SVG elements */
:deep(.highcharts-container),
:deep(.highcharts-root) {
  width: 100% !important;
  height: 100% !important;
  overflow: visible !important;
}

:deep(.highcharts-credits) {
  display: none !important;
}
</style>
