<template>
  <widget-wrapper
    title="Posiciones abiertas"
    widget-id="widget-open-positions"
    widget-name="Posiciones abiertas"
    :has-help="true"
    :help-url="url_iframe"
    :help-title="help_tittle"
    theme="dark"
    :has-alternate-action="true"
    alternate-action-icon="launch"
    alternate-action-url="/app/portfolio"
    @close-widget="_closeWidget"
    @show-help="_sendDataToIframe"
  >
    <!-- Loading State -->
    <div v-if="loading" class="widget-loading">
      <div class="spinner"></div>
    </div>
    
    <!-- Content Area -->
    <div v-else class="open-positions-content">
      <!-- Portfolio Selector - Always visible when portfolios exist -->
      <div v-if="portfolio_list.length > 0" class="portfolio-selector">
        <select v-model="value_selected" @change="_selectPortfolio">
          <option value="" disabled>Selecciona cartera</option>
          <option 
            v-for="portfolio in portfolio_list"
            :key="portfolio.value"
            :value="portfolio.value"
          >
            {{ portfolio.name }}
          </option>
        </select>
      </div>
      <div v-else class="no-portfolios">
        No hay carteras disponibles.
      </div>
      
      <!-- Content Section - Shows either positions or no data message -->
      <div v-if="value_selected" class="content-section">
        <!-- Positions List -->
        <div v-if="there_are_data" class="positions-list-container">
          <div class="list-header">
            <span class="col-ticker">Ticker</span>
            <span class="col-entry">Entrada</span>
            <span class="col-shares">Acciones</span>
            <span class="col-amount">Montante</span>
            <span class="col-risk">Riesgo</span>
          </div>
          <div class="positions-list">
            <div 
              v-for="(position, index) in open_positions" 
              :key="index"
              class="position-item"
              @click="openPositionGD(position.ticker)"
            >
              <span class="col-ticker">{{ position.ticker }}</span>
              <span class="col-entry">{{ position.entry_type_name }}</span>
              <span class="col-shares">{{ position.instrument_number }}</span>
              <span class="col-amount">{{ position.amount }}€</span>
              <span class="col-risk">{{ position.risk }}%</span>
            </div>
          </div>
        </div>
        
        <!-- No Data Message -->
        <div v-else class="no-data">
          <p>No tienes posiciones abiertas en esta cartera.</p>
        </div>
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import GetPortfolios from "@/components/codeComponents/getPortfolios.js";
import APICaller from "@/components/codeComponents/APICaller.js";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";
import { mapGetters } from "vuex";

export default {
  mixins: [APICaller, GetPortfolios],
  name: "OpenPositions",
  components: {
    WidgetWrapper,
  },
  data() {
    return {
      value_selected: "",
      open_positions: [],
      widget_name: { id: "widget-open-positions", name: "Posiciones abiertas" },
      there_are_data: false,
      portfolio_list: [],
      loading: true,
      url_iframe: "https://player.vimeo.com/video/187303863?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre posiciones abiertas",
    };
  },
  created() {
    this._loadData();
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },

    _loadData() {
      this.loading = true;
      this._getPortfolios();
    },
    
    _getPortfolios() {
      let success = (response) => {
        if (response.data && response.data.length > 0) {
          this.portfolio_list = response.data.map(p => ({
            name: p.name,
            value: p.idc
          }));
          this._getDefaultPortfolio();
        } else {
          this.portfolio_list = [];
          this.loading = false;
        }
      };
      
      let failure = () => {
        console.error("Failed to load portfolios for Open Positions");
        this.portfolio_list = [];
        this.loading = false;
      };

      let url = "/api/v1/portfolio/list"; 
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(failure, "ERR-PORTF-OP", "Error fetching portfolios."); 
      this._getAPICall(url, successHandler, failureHandler, { loadingOverlay: false });
    },

    _getDefaultPortfolio() {
      if (this.portfolio_list.length === 0) {
        this.loading = false;
        return;
      }
      
      let success = (response) => {
        if (response.data && response.data.idc) {
          this.value_selected = response.data.idc;
        } else {
          this.value_selected = this.portfolio_list[0].value;
        }
        this._getOpenPositions();
      };
      
      let failure = () => {
        console.error("Failed to load default portfolio for Open Positions");
        this.value_selected = this.portfolio_list[0].value;
        this._getOpenPositions();
      };

      let url = "/api/v1/portfolio-invest-preferences";
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(failure, "150-OP", "Error recuperando preferencias de inversión."); 
      this._getAPICall(url, successHandler, failureHandler, { loadingOverlay: false });
    },

    _getOpenPositions() {
      if (!this.value_selected) {
        this.loading = false;
        this.there_are_data = false;
        this.open_positions = [];
        return;
      }
      
      this.loading = true;
      this.open_positions = [];
      this.there_are_data = false;
      
      let success = (response) => {
        const data = response.data || [];
        
        if (data.length > 0) {
          this.there_are_data = true;
          this.open_positions = data.map(item => ({
            ticker: item.ticker,
            entry_type_name: item.entry_type_name,
            instrument_number: item.instrument_number,
            amount: item.amount,
            risk: item.risk
          }));
        } else {
          this.there_are_data = false;
        }
        this.loading = false;
      };
      
      let failure = () => {
        console.error("Failed to load open positions");
        this.there_are_data = false;
        this.open_positions = [];
        this.loading = false;
      }

      let url = "/api/v1/portfolio/open_positions/" + this.value_selected;
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(failure, "149", "Error recuperando posiciones abiertas.");
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _selectPortfolio() {
      this._getOpenPositions();
    },
    
    openPositionGD(ticker) {
      window.open(this.$detectEnvironment() + "/app/graficador-dinamico/" + ticker, "_blank");
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-background, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

.open-positions-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.portfolio-selector {
  padding: 12px 16px;
  border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
  flex-shrink: 0;
  
  select {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
    border-radius: 6px;
    background-color: var(--color-surface, #20202B);
    color: var(--color-text-primary, #FFFFFF);
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M2.5 4L6 7.5L9.5 4' stroke='%23BFC2CD' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 12px;
    
    &:focus {
      outline: none;
      border-color: var(--primary, #00aedd);
      box-shadow: 0 0 0 1px var(--primary, #00aedd);
    }

    &:hover {
      border-color: var(--primary, #00aedd);
    }
    
    option {
      background-color: var(--color-background, #0F0F1A);
      color: var(--color-text-primary, #FFFFFF);
      padding: 10px;
    }
  }
}

.no-portfolios {
   padding: 12px 16px;
   color: var(--color-text-secondary, #BFC2CD);
   font-size: 14px;
   text-align: center;
   font-style: italic;
}

.positions-list-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.list-header {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  font-size: 11px;
  font-weight: 600;
  color: var(--color-text-secondary, #BFC2CD);
  border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
  background-color: var(--color-surface, #20202B);
  flex-shrink: 0;
}

.positions-list {
  flex: 1;
  overflow-y: auto;
  padding: 8px 16px;
}

.position-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  font-size: 12px;
  border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.1));
  cursor: pointer;
  transition: background-color 0.15s ease;
  
  &:hover {
    background-color: var(--color-active-surface, #193141);
  }
  
  &:last-child {
    border-bottom: none;
  }
}

.col-ticker { 
  width: 20%; 
  font-weight: 500; 
  color: var(--color-text-primary, #FFFFFF); 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4px;
}

.col-entry { 
  width: 20%; 
  text-align: center; 
  color: var(--color-text-secondary, #BFC2CD); 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4px;
}

.col-shares {
  width: 18%;
  text-align: center; 
  color: var(--color-text-secondary, #BFC2CD);
  padding-right: 4px;
}

.col-amount {
  width: 22%;
  text-align: right;
  color: var(--color-text-secondary, #BFC2CD);
  padding-right: 4px;
}

.col-risk { 
  width: 15%; 
  text-align: right; 
  font-weight: 600; 
  color: var(--primary, #00aedd); 
}

.no-data {
  @extend .empty-state;
  
  p {
    color: var(--color-text-secondary, #BFC2CD);
    font-size: 14px;
  }
}

.content-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>
