<template>
  <widget-wrapper
    title="Direccionalidad"
    widget-id="widget-directionality"
    widget-name="Direccionalidad"
    :has-help="true"
    :help-url="url_iframe"
    :help-title="help_tittle"
    :has-alternate-action="true"
    alternate-action-icon="launch"
    alternate-action-url="/app/directionality"
    theme="dark"
    @close-widget="_closeWidget"
    @show-help="_sendDataToIframe"
  >
    <div v-if="loading" class="widget-loading">
      <div class="spinner"></div>
    </div>
    
    <div v-else class="directionality-content">
      <!-- Modern Filter Tabs -->
      <div class="filter-tabs">
        <button 
          v-for="option in timeframes" 
          :key="option.id"
          class="tab-button" 
          :class="{ active: activeTimeframe === option.id }"
          @click="selectTimeframe(option.id)"
        >
          {{ option.label }}
        </button>
      </div>

      <!-- Countries Data -->
      <div class="countries-container">
        <div 
          v-for="country in sortedCountries" 
          :key="country.code"
          class="country-item"
        >
          <div class="country-name">{{ country.name }}</div>
          <div class="country-value-container">
            <div 
              class="country-value-bar" 
              :style="{ width: country.value + '%' }"
              :class="getValueColorClass(country.value)"
            ></div>
            <span class="country-value-text">{{ Math.round(country.value) }}%</span>
          </div>
        </div>
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import axios from 'axios';
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";

export default {
  name: "Directionality",
  components: {
    WidgetWrapper
  },
  data() {
    return {
      loading: true,
      timeframes: [
        { id: 'semanal', label: 'Corto plazo', endpoint: '/directionality/semanal/get' },
        { id: 'combinado', label: 'Medio plazo', endpoint: '/directionality/combinado/get' },
        { id: 'mensual', label: 'Largo plazo', endpoint: '/directionality/mensual/get' }
      ],
      activeTimeframe: 'combinado',
      countries: [],
      widget_name: { id: "widget-directionality", name: "Direccionalidad" },
      url_iframe: "https://player.vimeo.com/video/187303455?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre direccionalidad",
    };
  },
  computed: {
    sortedCountries() {
      return [...this.countries].sort((a, b) => b.value - a.value);
    }
  },
  mounted() {
    this.fetchDirectionalityData();
  },
  methods: {
    selectTimeframe(timeframeId) {
      this.activeTimeframe = timeframeId;
      this.fetchDirectionalityData();
    },
    
    getValueColorClass(value) {
      if (value < 20) return 'color-red';
      if (value < 40) return 'color-orange';
      if (value < 60) return 'color-yellow';
      if (value < 80) return 'color-green';
      return 'color-blue';
    },
    
    fetchDirectionalityData() {
      this.loading = true;
      
      const selectedTimeframe = this.timeframes.find(t => t.id === this.activeTimeframe);
      
      axios.get(selectedTimeframe.endpoint)
        .then(response => {
          // Translate country names to Spanish
          this.countries = response.data.map(country => ({
            ...country,
            name: this.getSpanishCountryName(country.code, country.name)
          }));
          this.loading = false;
        })
        .catch(error => {
          console.error('Error fetching directionality data:', error);
          this.countries = [];
          this.loading = false;
        });
    },
    
    getSpanishCountryName(code, fallbackName) {
      const countryTranslations = {
        'NL': 'Países Bajos',
        'AU': 'Australia',
        'DE': 'Alemania',
        'BE': 'Bélgica',
        'IN': 'India',
        'HU': 'Hungría',
        'DK': 'Dinamarca',
        'CH': 'Suiza',
        'FI': 'Finlandia',
        'IE': 'Irlanda',
        'ID': 'Indonesia',
        'MY': 'Malasia',
        'PT': 'Portugal',
        'GB': 'Inglaterra',
        'ES': 'España',
        'RU': 'Rusia',
        'MX': 'México',
        'IT': 'Italia',
        'US': 'Estados Unidos',
        'NZ': 'Nueva Zelanda',
        'NO': 'Noruega',
        'FR': 'Francia',
        'PH': 'Filipinas',
        'BR': 'Brasil',
        'TH': 'Tailandia',
        'CL': 'Chile',
        'CN': 'China',
        'SE': 'Suecia',
        'TW': 'Taiwán',
        'JP': 'Japón',
        'IL': 'Israel',
        'CA': 'Canadá',
        'AT': 'Austria',
        'PL': 'Polonia'
      };
      
      return countryTranslations[code] || fallbackName;
    },
    
    _sendDataToIframe() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },
    
    _closeWidget() {
      this.$emit("widget_name", this.widget_name);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-background, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

.directionality-content {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.filter-tabs {
  display: flex;
  gap: 8px;
  border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
  padding-bottom: 8px;
  justify-content: space-between;
  
  .tab-button {
    background: none;
    border: none;
    color: var(--color-text-inactive, rgba(255, 255, 255, 0.6));
    font-size: 12px;
    padding: 6px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    
    &:hover {
      color: var(--color-text-primary, #FFFFFF);
      background-color: var(--color-active-surface, #193141);
    }
    
    &.active {
      color: var(--color-text-primary, #FFFFFF);
      background-color: var(--color-active-surface, #193141);
      font-weight: 500;
    }
  }
}

.countries-container {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.country-item {
  display: flex;
  align-items: center;
  padding: 4px 0;
}

.country-name {
  width: 90px;
  font-size: 12px;
  font-weight: 500;
  color: var(--color-text-primary, #FFFFFF);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.country-value-container {
  flex: 1;
  height: 20px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.country-value-bar {
  height: 100%;
  border-radius: 4px;
  transition: width 0.3s ease;
  
  &.color-red {
    background-color: #FF5252;
  }
  
  &.color-orange {
    background-color: #FFA726;
  }
  
  &.color-yellow {
    background-color: #FFEB3B;
  }
  
  &.color-green {
    background-color: #66BB6A;
  }
  
  &.color-blue {
    background-color: #42A5F5;
  }
}

.country-value-text {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 11px;
  font-weight: 600;
  color: var(--color-text-primary, #FFFFFF);
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}
</style>
