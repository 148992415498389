<template>
  <!-- WidgetWrapper now handles the header and outer frame -->
  <widget-wrapper
    :title="widget_name.name"
    :widget-id="widget_name.id"
    :widget-name="widget_name.name"
    :has-help="true"
    :help-url="url_iframe"
    :help-title="help_tittle"
    :has-alternate-action="true"
    alternate-action-icon="launch"
    :alternate-action-url="portfolioRedirectUrl"
    theme="dark"
    @close-widget="_closeWidget"
    @show-help="_sendDataToIframe"
    :has-access="_g_UserFeatures.permissions.capital_curve"
  >
    <!-- This slot content replaces the previous outer div -->
    <!-- No-access message is now handled by WidgetWrapper OR can be kept here -->
    <!-- Let's keep the specific message here for clarity -->
    <div v-if="!_g_UserFeatures.permissions.capital_curve" class="no-access-message-internal">
      <span class="material-icons">lock</span>
      <p>Su plan actual no cuenta con acceso a este widget.</p>
      <a href="/app/store" class="upgrade-link">Actualizar plan</a>
    </div>

    <!-- Main content when user has access -->
    <div v-else class="curve-capital-content">
      <div v-if="loading" class="widget-loading">
        <div class="spinner"></div>
      </div>

      <!-- Content Body now includes the padding previously in WidgetWrapper -->
      <div v-else class="content-body">
        <!-- Portfolio Selector -->
        <div class="portfolio-selector-container">
          <select
            v-model="value_selected"
            @change="_handlePortfolioChange"
            class="modern-select"
          >
            <option v-if="!value_selected" value="" disabled>Selecciona cartera</option>
            <option v-for="portfolio in portfolio_list" :key="portfolio.name" :value="portfolio.name">
              {{ portfolio.value }}
            </option>
          </select>
        </div>

        <!-- Chart Container - will flex to fill remaining space -->
        <div class="chart-container" ref="chartcontainer">
           <!-- Target Div for Highcharts - takes 100% of parent -->
           <div :id="chartId" style="height: 100%; width: 100%; display: block;"></div>
        </div>
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import GetPortfolios from "@/components/codeComponents/getPortfolios.js";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue"; // Adjusted path if needed
import Highcharts from "highcharts";

export default {
  mixins: [APICaller, GetPortfolios],
  name: "CurveCapital", // Standard Vue naming convention
  components: {
    WidgetWrapper // Register the wrapper
  },
  data() {
    return {
      value_selected: "",
      portfolio_list: [],
      series: [],
      widget_name: { id: "widget-curve-capital", name: "Curva de capital" },
      loading: true,
      url_iframe: "https://player.vimeo.com/video/187303173?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre curva de capital",
      chartInstance: null,
      _resizeHandler: null,
      chartId: 'highchart-content-curve-' + Math.random().toString(36).substring(7)
    };
  },
  computed: {
    ...mapGetters(["is_mobile_device", "_g_UserFeatures", "_g_User"]), // Assuming these are correctly mapped
    portfolioRedirectUrl() {
      // Construct URL only if value_selected is valid
      return this.value_selected ? `/app/portfolio/${this.value_selected}/capital-curve` : '/app/portfolio';
    }
  },
  mounted() {
    // Check permission before initializing
    if (this._g_UserFeatures.permissions.capital_curve) {
      this._initializeData(true);
    } else {
      this.loading = false; // No loading needed if no access
    }
  },
  beforeDestroy() {
    // Clean up chart instance
    if (this.chartInstance) {
      try {
        this.chartInstance.destroy();
      } catch (e) {
        console.error("Error destroying chart:", e);
      }
      this.chartInstance = null;
    }
    // Clean up resize listener
    if (this._resizeHandler) {
      window.removeEventListener('resize', this._resizeHandler);
      this._resizeHandler = null;
    }
  },
  methods: {
    async _initializeData(isInitialLoad = false) {
      
      this.loading = true;
      try {
        // Fetch portfolios only on initial load or if list is empty
        if (isInitialLoad || !this.portfolio_list || this.portfolio_list.length === 0) {
          
          this.portfolio_list = [];
          await this._getPortfolios(); // Assumes this method populates portfolio_list
          
        }

        // Select default portfolio only on initial load
        if (isInitialLoad) {
                      await this._defaultPortfolio(); // Assumes this sets value_selected
           
        }

        // Fetch curve data for the currently selected portfolio
        
        await this._getCurveCapital();
        

      } catch (error) {
        console.error("INIT: Initialization failed:", error);
        this.series = []; // Clear series on error
      } finally {
         
         this.loading = false;
         // Ensure DOM is updated before rendering chart
         this.$nextTick(() => {
           
           this._renderChart();
         });
      }
    },

    _sendDataToIframe() {
        // Emit data needed by the parent to show the help iframe
        this.$emit('show-help', { // Use the event defined in WidgetWrapper props if it expects an object
            url: this.url_iframe,
            title: this.help_tittle
        });
        // OR if the parent expects separate events:
        // this.$emit("url_iframe", this.url_iframe);
        // this.$emit("help_tittle", this.help_tittle);
        // this.$emit("load_iframe"); // Trigger the parent to show the iframe
    },


    _getCurveCapital() {
      // --- Using Promise for async handling ---
      return new Promise((resolve, reject) => {
        const currentPortfolioId = this.value_selected;
        

        // Handle case where no portfolio is selected
        if (!currentPortfolioId) {
          console.warn('GET_CURVE: No portfolio selected, cannot fetch data.');
          this.series = []; // Clear existing series
          resolve(); // Resolve promise successfully (no data)
          return;
        }

        // --- API Call Setup ---
        const success = (response) => {
          
          this.series = []; // Reset series before processing new data

          // Validate response structure
          if (response.data && Array.isArray(response.data.series) && Array.isArray(response.data.categories)) {
            try {
              // Process categories (timestamps)
              const categories = response.data.categories.map(dateStr => {
                const parts = dateStr.split('-'); // Assuming DD-MM-YYYY
                if (parts.length === 3) {
                  // Date.UTC(year, monthIndex (0-11), day)
                  return Date.UTC(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
                }
                console.warn(`GET_CURVE: Invalid date format skipped: ${dateStr}`);
                return null; // Skip invalid date formats
              }).filter(ts => ts !== null); // Remove nulls

              if (categories.length === 0) {
                 console.warn("GET_CURVE: No valid categories found after processing.");
              }

              // Process each series object from the API
              response.data.series.forEach(s => {
                // Basic validation of the series object
                if (!s || typeof s.name !== 'string' || !Array.isArray(s.data)) {
                   console.warn("GET_CURVE: Skipping invalid series object:", s);
                   return; // Skip this series
                }

                // Ensure data array length matches categories length for proper pairing
                const seriesData = s.data.slice(0, categories.length);

                // Map data points to [timestamp, value] format, ensuring numeric values
                const processedData = seriesData.map((value, index) => {
                   const numericValue = (value === null || value === undefined || value === '') ? null : Number(value);
                   // Pair valid category timestamp with valid numeric value
                   if (categories[index] !== undefined && numericValue !== null && !isNaN(numericValue)) {
                       return [categories[index], numericValue];
                   }
                   return null; // Exclude points with missing category or invalid value
                }).filter(point => point !== null); // Remove null points

                // Assign color based on series name (case-insensitive)
                let seriesColor = '#888888'; // Default grey color
                const lowerCaseName = s.name.toLowerCase();
                if (lowerCaseName.includes('ficticio')) {
                  seriesColor = '#BFC2CD'; // Specific light grey for 'ficticio'
                } else if (lowerCaseName.includes('real')) {
                  seriesColor = '#00aedd'; // Specific blue for 'real'
                }
                

                // Add the processed series to the component's data
                this.series.push({
                   name: s.name,
                   data: processedData,
                   color: seriesColor,
                   lineWidth: 2 // Consistent line width
                });
              });

            } catch (e) {
               console.error("GET_CURVE: Error processing chart data:", e);
               this.series = []; // Reset on processing error
            }
          } else {
            // Log warning if API response structure is unexpected
            console.warn(`GET_CURVE: Invalid API response structure for ${currentPortfolioId}. Missing 'series' or 'categories'.`);
            this.series = [];
          }

          
          resolve(); // Resolve promise after successful processing
        };

        const failure = (error) => {
           console.error(`GET_CURVE: API Error for ${currentPortfolioId}:`, error);
           this.series = []; // Clear series on API failure
           reject(error); // Reject promise with the error
        };

        // --- Execute API Call ---
        const url = `/api/v1/portfolio/get-capital-curve/${currentPortfolioId}`;
        const successHandler = new APICaller.SuccessHandler(success);
        // Assuming FailureHandler constructor takes (errorCode, defaultMessage)
        const failureHandler = new APICaller.FailureHandler("140", "Error cargando curva de capital.");
        const apiCallConfig = { loadingOverlay: false }; // Don't show global loading overlay
        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      });
    },

     _defaultPortfolio() {
       return new Promise((resolve) => {
        // Check if a valid portfolio is already selected
        if (this.value_selected && this.portfolio_list.some(p => p.name === this.value_selected)) {
           
           resolve();
           return;
        }

        
        const success = (response) => {
          let defaultSet = false;
          // Check if API returned a default ID ('idc')
          if (response.data && response.data.idc) {
            const defaultIdc = response.data.idc;
            // Check if the default ID exists in the fetched portfolio list
            if (this.portfolio_list.some(p => p.name === defaultIdc)) {
              this.value_selected = defaultIdc;
              
              defaultSet = true;
            } else {
              console.warn(`DEFAULT: Default ID ${defaultIdc} received but not found in portfolio list.`);
            }
          } else {
             console.warn('DEFAULT: No default preference ID received from API.');
          }

          // Fallback: If no default was set AND the portfolio list is not empty
          if (!defaultSet && this.portfolio_list.length > 0) {
             this.value_selected = this.portfolio_list[0].name; // Select the first portfolio
             
          } else if (!defaultSet && this.portfolio_list.length === 0) {
              // Ensure value_selected is reset if no portfolios exist
              this.value_selected = "";
              
          }
          resolve(); // Resolve after setting (or attempting to set) the value
        };

        const failure = (error) => {
           console.error("DEFAULT: API Error fetching preferences:", error);
           // Fallback on error: If nothing is selected and list has items, select the first one
           if (!this.value_selected && this.portfolio_list.length > 0) {
             this.value_selected = this.portfolio_list[0].name;
             
           } else if (!this.value_selected) { // If still no selection possible
               this.value_selected = "";
               
           }
           resolve(); // Resolve even on failure to allow initialization to continue
        };

         const url = "/api/v1/portfolio-invest-preferences";
         const successHandler = new APICaller.SuccessHandler(success);
         const failureHandler = new APICaller.FailureHandler("141", "Error cargando preferencias de cartera.");
         const apiCallConfig = { loadingOverlay: false };
         this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
       });
    },


    _renderChart() {
      // Get the target DOM element
      const chartElement = document.getElementById(this.chartId);
      if (!chartElement) {
        console.error(`RENDER: Chart container element #${this.chartId} not found in DOM.`);
        return; // Cannot render without a target
      }

      // Destroy previous instance if it exists
      if (this.chartInstance) {
        
        try { this.chartInstance.destroy(); } catch(e) { /* Ignore errors on destroy */ }
        this.chartInstance = null;
      }

      // Provide minimal data structure if API returned no series, prevents Highcharts error
      const chartSeries = this.series && this.series.length > 0 ? this.series : [{
           name: 'No data', // Placeholder name
           data: [],        // Empty data array
           color: '#555555' // Placeholder color
      }];

      try {
        
        
        // --- Highcharts Configuration ---
        this.chartInstance = Highcharts.chart(this.chartId, {
          chart: {
            type: "line",
            zoomType: "x",
            backgroundColor: 'transparent',
            height: null,        // Allow chart to determine height based on container
            style: { fontFamily: '"Open Sans", sans-serif' },
            spacingTop: 5,
            spacingBottom: 10,
            spacingLeft: 10,
            spacingRight: 10,
            // Carefully control reflow triggers to avoid recursion
            events: {
              // NO automatic reflows in events to prevent recursion
            }
          },
          title: { text: null },
          xAxis: {
             type: "datetime",
             dateTimeLabelFormats: {
                millisecond: '%H:%M:%S.%L', second: '%H:%M:%S', minute: '%H:%M',
                hour: '%H:%M', day: '%e. %b', week: '%e. %b',
                month: '%b \'%y', year: '%Y'
             },
             lineColor: 'var(--color-outline, rgba(255, 255, 255, 0.3))',
             tickColor: 'var(--color-outline, rgba(255, 255, 255, 0.3))',
             labels: { style: { color: 'var(--color-text-secondary, #BFC2CD)' } }
          },
          yAxis: {
             title: { text: null },
             gridLineColor: 'var(--color-outline, rgba(255, 255, 255, 0.1))',
             labels: {
                style: { color: 'var(--color-text-secondary, #BFC2CD)' },
                formatter: function() {
                   if (Math.abs(this.value) >= 1000000) {
                       return (this.value / 1000000) + 'M';
                   } else if (Math.abs(this.value) >= 1000) {
                       return (this.value / 1000) + 'k';
                   }
                   return this.value;
                }
             }
          },
          tooltip: {
             backgroundColor: 'rgba(32, 32, 43, 0.9)',
             borderColor: 'rgba(255, 255, 255, 0.3)',
             style: { color: '#E0E0E0' },
             shared: true,
             crosshairs: true,
             dateTimeLabelFormats: {
                day: '%A, %e %b %Y', hour: '%A, %e %b %Y %H:%M',
                minute: '%A, %e %b %Y %H:%M', second: '%A, %e %b %Y %H:%M:%S'
             },
             pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>'
          },
          plotOptions: {
             series: {
                animation: false,
                states: { hover: { lineWidthPlus: 1 } }
             },
             line: {
                marker: {
                   enabled: false,
                   radius: 3,
                   states: { hover: { enabled: true, radius: 4 } }
                },
                lineWidth: 2
             }
          },
          series: chartSeries,
          credits: { enabled: false },
          legend: {
            enabled: chartSeries.length > 1 && chartSeries[0].name !== 'No data',
            itemStyle: { color: 'var(--color-text-secondary, #BFC2CD)' },
            itemHoverStyle: { color: 'var(--color-text-primary, #FFFFFF)' }
          },
          // Simpler responsive rules to avoid recursive reflows
          responsive: {
            rules: [{
                condition: { maxWidth: 500 },
                chartOptions: {
                    legend: { layout: 'horizontal', align: 'center', verticalAlign: 'bottom' }
                }
            }]
          }
        });

        // Set up resize handler manually but with careful debouncing
        if (!this._resizeHandler) {
          let resizeTimeout;
          this._resizeHandler = () => {
              // Clear any existing timeout to prevent multiple reflows
              if (resizeTimeout) {
                  clearTimeout(resizeTimeout);
              }
              
              // Set a new timeout with a delay
              resizeTimeout = setTimeout(() => {
                  if (this.chartInstance) {
                      // Get container dimensions before reflow
                      const container = this.$refs.chartcontainer;
                      const containerWidth = container ? container.offsetWidth : 0;
                      const containerHeight = container ? container.offsetHeight : 0;
                      
                      
                      
                      // Only reflow if container has actual dimensions
                      if (containerWidth > 0 && containerHeight > 0) {
                          
                          
                          // Set explicit dimensions before reflow
                          this.chartInstance.setSize(containerWidth, containerHeight, false);
                      }
                  }
              }, 300); // Longer delay to ensure resize events have settled
          };
          
          window.addEventListener('resize', this._resizeHandler);
          
        }
        
        // Initial one-time sizing with delay
        setTimeout(() => {
            if (this.chartInstance) {
                const container = this.$refs.chartcontainer;
                if (container && container.offsetWidth > 0 && container.offsetHeight > 0) {
                    
                    this.chartInstance.setSize(container.offsetWidth, container.offsetHeight, false);
                }
            }
        }, 250);

      } catch (error) {
        console.error(`RENDER: Error creating Highcharts instance #${this.chartId}:`, error);
        // Optionally display an error message in the chart container
        if (chartElement) {
            chartElement.innerHTML = `<div style='color: red; padding: 20px; text-align: center;'>Error rendering chart.</div>`;
        }
      }
    },

    _handlePortfolioChange() {
      
      // Don't need to re-fetch portfolio list or default
      this.loading = true; // Show loading indicator
      this.series = []; // Clear old series immediately
      if(this.chartInstance) {
          // Optionally update chart with no data state while loading
          this.chartInstance.update({
              series: [{ name: 'Loading...', data: [], color: '#555'}]
          }, true, false); // redraw, no animation
      }

      this._getCurveCapital()
        .then(() => {
            
            // Data is ready, _renderChart will handle rendering/updating
        })
        .catch((error) => {
            console.error("CHANGE: Failed to fetch curve data:", error);
            // Ensure series is empty on error
            this.series = [];
        })
        .finally(() => {
            this.loading = false; // Hide loading indicator
            // Use nextTick to ensure loading state change is reflected before rendering
            this.$nextTick(() => {
                
                this._renderChart(); // Render/update chart with new data (or empty state on error)
            });
        });
    },

    _closeWidget() {
        // Emit the event expected by the parent grid/dashboard
        this.$emit("close-widget", { // Use the event name from WidgetWrapper props
            id: this.widget_name.id,
            name: this.widget_name.name
        });
        // OR if parent expects 'widget_name' event:
        // this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

/* Loading Spinner Styles */
.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-background, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

/* Main Content Area Styling */
.curve-capital-content {
  display: flex;
  flex-direction: column;
  height: 100%; /* Fill the slot area provided by WidgetWrapper */
  width: 100%;
  overflow: hidden; /* Prevent scrolling within this container */
  background-color: var(--color-surface, #20202B); /* Ensure background consistency */
  position: relative; /* Add this to ensure proper layout */
}

/* Content Body - Applies Padding */
.content-body {
  flex: 1; /* Grow to fill available space */
  min-height: 0; /* Crucial for flex shrinking */
  display: flex;
  flex-direction: column;
  /* Apply the padding that was removed from WidgetWrapper */
  padding: 16px 20px;
  gap: 12px; /* Space between selector and chart */
  overflow: hidden; /* Prevent internal scrolling */
  position: relative; /* Add this to ensure proper layout */
  height: 100%; /* Ensure it takes full height */

  /* Adjust padding for smaller screens if WidgetWrapper does */
  @media (max-width: 767px) {
      padding: 12px 16px;
  }
}

/* Portfolio Selector Styling */
.portfolio-selector-container {
  padding-bottom: 12px; /* Space below the selector */
  border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.1));
  flex-shrink: 0; /* Prevent selector from shrinking vertically */
  margin-bottom: 8px; /* Optional extra space */

  .modern-select {
    width: 100%;
    padding: 10px 30px 10px 12px; /* Space for text and arrow */
    border: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
    border-radius: 6px;
    background-color: var(--color-surface, #20202B); /* Match background */
    color: var(--color-text-primary, #FFFFFF);
    font-size: 14px;
    cursor: pointer;
    appearance: none; /* Remove default OS arrow */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M2.5 4L6 7.5L9.5 4' stroke='%23BFC2CD' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E"); /* Custom arrow */
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 12px;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;

    &:focus {
      outline: none;
      border-color: var(--primary, #00aedd);
      box-shadow: 0 0 0 1px var(--primary, #00aedd); /* Focus indicator */
    }

    &:hover {
      border-color: var(--primary, #00aedd); /* Hover indicator */
    }

    option {
      background-color: var(--color-background, #0F0F1A); /* Dropdown background */
      color: var(--color-text-primary, #FFFFFF);
      padding: 8px 12px;
    }

     /* Style for the disabled placeholder option */
    option[disabled] {
        color: var(--color-text-secondary, #BFC2CD);
    }
  }
}

/* Chart Container Styling */
.chart-container {
  flex: 1; /* Grow to fill remaining vertical space */
  min-height: 200px; /* Ensure minimum height for chart to render properly */
  width: 100%;
  position: relative; /* Needed for Highcharts positioning */
  overflow: hidden; /* Clip any chart overflow */
  display: flex; /* Use flex to ensure child fills space */
  flex-direction: column; /* Stack children vertically */
}

/* The actual Highcharts container element */
.chart-container > div {
  flex: 1; /* Make the chart div fill the container */
  min-height: 0; /* Allow flex shrinking */
  width: 100%;
}

/* Deep selector for Highcharts SVG elements - Ensure they fill their container */
:deep(.highcharts-container),
:deep(.highcharts-root) {
  width: 100% !important;
  height: 100% !important;
}

/* Internal No Access Message Styling (if not using WidgetWrapper's) */
.no-access-message-internal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  text-align: center;
  color: var(--color-text-secondary, #BFC2CD);
  gap: 12px;
  height: 100%; /* Fill the space if access denied */
  background-color: var(--color-surface, #20202B); /* Match background */
  border-radius: 0 0 10px 10px; /* Match wrapper corners */

  .material-icons {
    font-size: 32px;
    margin-bottom: 8px;
    color: var(--color-text-secondary);
  }

  p {
    margin: 0 0 16px;
    font-size: 14px;
  }

  .upgrade-link {
    color: var(--primary, #00aedd);
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    padding: 6px 12px;
    border: 1px solid var(--primary, #00aedd);
    border-radius: 15px;
    transition: background-color 0.2s ease, color 0.2s ease;


    &:hover {
      background-color: var(--primary, #00aedd);
      color: white;
      text-decoration: none;
    }
  }
}
</style>