<template>
  <!-- WidgetWrapper now handles the header and outer frame -->
  <widget-wrapper
    :title="widget_name.name"
    :widget-id="widget_name.id"
    :widget-name="widget_name.name"
    :has-help="true"
    :help-url="url_iframe"
    :help-title="help_tittle"
    :has-alternate-action="true"
    alternate-action-icon="launch"
    :alternate-action-url="portfolioRedirectUrl"
    theme="dark"
    @close-widget="_closeWidget"
    @show-help="_sendDataToIframe"
    :has-access="true"
  >
    <!-- Main content container -->
    <div class="portfolio-index-content">
      <div v-if="loading" class="widget-loading">
        <div class="spinner"></div>
      </div>

      <!-- Content Body -->
      <div v-else class="content-body">
        <!-- Portfolio Selector -->
        <div class="portfolio-selector-container">
          <select
            v-model="value_selected"
            @change="_handlePortfolioChange"
            class="modern-select"
          >
            <option v-if="!value_selected" value="" disabled>Selecciona cartera</option>
            <option v-for="portfolio in portfolio_list" :key="portfolio.name" :value="portfolio.name">
              {{ portfolio.value }}
            </option>
          </select>
        </div>

        <!-- Chart Container -->
        <div class="chart-container" ref="chartcontainer">
          <!-- Target Div for Highcharts -->
          <div :id="chartId" style="height: 100%; width: 100%; display: block;"></div>
        </div>
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import { mapGetters } from "vuex";
import GetPortfolios from "@/components/codeComponents/getPortfolios.js";
import APICaller from "@/components/codeComponents/APICaller.js";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";
import Highcharts from "highcharts";

export default {
  mixins: [APICaller, GetPortfolios],
  name: "PortfolioIndex",
  components: {
    WidgetWrapper
  },
  data() {
    return {
      portfolio_list: [],
      portfolio_selected: [],
      value_selected: "",
      series: [],
      widget_name: { id: "widget-portfolio-index", name: "Índice de cartera" },
      loading: true,
      url_iframe: "https://player.vimeo.com/video/387654337?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre índice de cartera",
      chartInstance: null,
      _resizeHandler: null,
      chartId: 'highchart-content-portfolio-index-' + Math.random().toString(36).substring(7)
    };
  },
  computed: {
    ...mapGetters(["is_mobile_device", "_g_UserFeatures", "_g_User"]),
    portfolioRedirectUrl() {
      return this.value_selected ? `/app/portfolio/${this.value_selected}/portfolio-index` : '/app/portfolio';
    }
  },
  mounted() {
    this._initializeData(true);
  },
  beforeDestroy() {
    // Clean up chart instance
    if (this.chartInstance) {
      try {
        this.chartInstance.destroy();
      } catch (e) {
        console.error("Error destroying chart:", e);
      }
      this.chartInstance = null;
    }
    // Clean up resize listener
    if (this._resizeHandler) {
      window.removeEventListener('resize', this._resizeHandler);
      this._resizeHandler = null;
    }
  },
  methods: {
    async _initializeData(isInitialLoad = false) {
      
      this.loading = true;
      
      try {
        // Fetch portfolios only on initial load or if list is empty
        if (isInitialLoad || !this.portfolio_list || this.portfolio_list.length === 0) {
          
          this.portfolio_list = [];
          await this._getPortfolios();
          
        }

        // Select default portfolio
        if (isInitialLoad) {
          
          await this._defaultPortfolio();
          
        }

        // Fetch portfolio index data
        
        await this._getPorfolioIndex();
        

      } catch (error) {
        console.error("INIT: Initialization failed:", error);
        this.series = [];
      } finally {
        
        this.loading = false;
        this.$nextTick(() => {
          
          this._renderChart();
        });
      }
    },
    
    _sendDataToIframe() {
      // Emit data needed by the parent to show the help iframe
      this.$emit('show-help', {
        url: this.url_iframe,
        title: this.help_tittle
      });
    },

    _getPorfolioIndex() {
      return new Promise((resolve, reject) => {
        const currentPortfolioId = this.value_selected;
        

        // Handle case where no portfolio is selected
        if (!currentPortfolioId) {
          console.warn('GET_INDEX: No portfolio selected, cannot fetch data.');
          this.series = [];
          resolve();
          return;
        }

        const success = (response) => {
          
          if (response.data && response.data.series) {
            // Parse date strings from categories if available
            const dateCategories = response.data.categories || [];
            const parsedDates = dateCategories.map(dateStr => {
              // Parse date strings in format "DD-MM-YYYY"
              const parts = dateStr.split('-');
              if (parts.length === 3) {
                // Create date using the parts (months are 0-indexed in JS Date)
                return Date.UTC(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
              }
              return null;
            });

            // Make a copy of the series data and ensure timestamps are properly handled
            this.series = response.data.series.map(series => {
              // Create a new series object with properly formatted data
              return {
                ...series,
                color: '#00aedd', // Set each series to red
                data: series.data.map((point, index) => {
                  // If we have parsed dates from categories and point is just a value
                  if (parsedDates.length > 0 && !Array.isArray(point) && index < parsedDates.length) {
                    return [parsedDates[index], point];
                  }
                  
                  // For existing [timestamp, value] pairs
                  if (Array.isArray(point) && point.length === 2) {
                    // If the first element is a string, try to parse it as a date
                    if (typeof point[0] === 'string') {
                      const parts = point[0].split('-');
                      if (parts.length === 3) {
                        // Parse "DD-MM-YYYY" format
                        const timestamp = Date.UTC(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
                        return [timestamp, point[1]];
                      }
                    } 
                    // Check if timestamp is in seconds and convert to ms if needed
                    else if (typeof point[0] === 'number') {
                      const timestamp = point[0] < 10000000000 
                        ? point[0] * 1000 // Convert seconds to milliseconds if timestamp looks like seconds
                        : point[0];      // Keep as is if already in milliseconds
                      
                      return [timestamp, point[1]];
                    }
                  }
                  return point; // Return unchanged if not in expected format
                })
              };
            });
          } else {
            console.warn(`GET_INDEX: Invalid API response structure for ${currentPortfolioId}`);
            this.series = [];
          }
          
          resolve();
        };

        const failure = (error) => {
          console.error(`GET_INDEX: API Error for ${currentPortfolioId}:`, error);
          this.series = [];
          reject(error);
        };

        const url = `/api/v1/portfolio/get-portfolio-index/${currentPortfolioId}`;
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler("154", "Error recuperando índice de cartera.");
        const apiCallConfig = { loadingOverlay: false };
        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      });
    },

    _defaultPortfolio() {
      return new Promise((resolve) => {
        // Check if a valid portfolio is already selected
        if (this.value_selected && this.portfolio_list.some(p => p.name === this.value_selected)) {
          
          resolve();
          return;
        }

        
        const success = (response) => {
          let defaultSet = false;
          
          // Check if API returned a default ID ('idc')
          if (response.data && response.data.idc) {
            const defaultIdc = response.data.idc;
            // Check if the default ID exists in the fetched portfolio list
            if (this.portfolio_list.some(p => p.name === defaultIdc)) {
              this.value_selected = defaultIdc;
              this.portfolio_selected = [{
                name: defaultIdc,
                value: response.data.name || this.portfolio_list.find(p => p.name === defaultIdc).value
              }];
              
              defaultSet = true;
            } else {
              console.warn(`DEFAULT: Default ID ${defaultIdc} received but not found in portfolio list.`);
            }
          } else {
            console.warn('DEFAULT: No default preference ID received from API.');
          }

          // Fallback: If no default was set AND the portfolio list is not empty
          if (!defaultSet && this.portfolio_list.length > 0) {
            this.value_selected = this.portfolio_list[0].name;
            this.portfolio_selected = [this.portfolio_list[0]];
            
          } else if (!defaultSet && this.portfolio_list.length === 0) {
            // Ensure value_selected is reset if no portfolios exist
            this.value_selected = "";
            this.portfolio_selected = [];
            
          }
          
          resolve();
        };

        const failure = (error) => {
          console.error("DEFAULT: API Error fetching preferences:", error);
          // Fallback on error: If nothing is selected and list has items, select the first one
          if (!this.value_selected && this.portfolio_list.length > 0) {
            this.value_selected = this.portfolio_list[0].name;
            this.portfolio_selected = [this.portfolio_list[0]];
            
          } else if (!this.value_selected) {
            this.value_selected = "";
            this.portfolio_selected = [];
            
          }
          
          resolve();
        };

        const url = "/api/v1/portfolio-invest-preferences";
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler("141", "Error cargando preferencias de cartera.");
        const apiCallConfig = { loadingOverlay: false };
        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      });
    },

    _renderChart() {
      // Get the target DOM element
      const chartElement = document.getElementById(this.chartId);
      if (!chartElement) {
        console.error(`RENDER: Chart container element #${this.chartId} not found in DOM.`);
        return;
      }

      // Destroy previous instance if it exists
      if (this.chartInstance) {
        
        try { this.chartInstance.destroy(); } catch(e) { /* Ignore errors on destroy */ }
        this.chartInstance = null;
      }

      // Provide minimal data structure if API returned no series
      const chartSeries = this.series && this.series.length > 0 ? this.series : [{
        name: 'No data',
        data: [],
        color: '#555555'
      }];

      try {
        
        
        // --- Highcharts Configuration ---
        this.chartInstance = Highcharts.chart(this.chartId, {
          chart: {
            type: "line",
            zoomType: "x",
            backgroundColor: 'transparent',
            height: null,
            style: { fontFamily: '"Open Sans", sans-serif' },
            spacingTop: 5,
            spacingBottom: 10,
            spacingLeft: 10,
            spacingRight: 10
          },
          title: { text: null },
          xAxis: {
            type: "datetime",
            dateTimeLabelFormats: {
              millisecond: '%H:%M:%S.%L', second: '%H:%M:%S', minute: '%H:%M',
              hour: '%H:%M', day: '%e. %b', week: '%e. %b',
              month: '%b \'%y', year: '%Y'
            },
            lineColor: 'var(--color-outline, rgba(255, 255, 255, 0.3))',
            tickColor: 'var(--color-outline, rgba(255, 255, 255, 0.3))',
            labels: { style: { color: 'var(--color-text-secondary, #BFC2CD)' } }
          },
          yAxis: {
            title: { text: null },
            gridLineColor: 'var(--color-outline, rgba(255, 255, 255, 0.1))',
            labels: {
              style: { color: 'var(--color-text-secondary, #BFC2CD)' }
            }
          },
          tooltip: {
            backgroundColor: 'rgba(32, 32, 43, 0.9)',
            borderColor: 'rgba(255, 255, 255, 0.3)',
            style: { color: '#E0E0E0' },
            shared: true,
            crosshairs: true,
            dateTimeLabelFormats: {
              day: '%A, %e %b %Y', hour: '%A, %e %b %Y %H:%M',
              minute: '%A, %e %b %Y %H:%M', second: '%A, %e %b %Y %H:%M:%S'
            },
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>'
          },
          plotOptions: {
            series: {
              animation: false,
              states: { hover: { lineWidthPlus: 1 } },
              color: '#FF0000' // Set default color to red for all series
            },
            line: {
              marker: {
                enabled: false,
                radius: 3,
                states: { hover: { enabled: true, radius: 4 } }
              },
              lineWidth: 2,
              color: '#FF0000' // Set default color to red for line type specifically
            }
          },
          series: chartSeries,
          credits: { enabled: false },
          legend: {
            enabled: chartSeries.length > 1 && chartSeries[0].name !== 'No data',
            itemStyle: { color: 'var(--color-text-secondary, #BFC2CD)' },
            itemHoverStyle: { color: 'var(--color-text-primary, #FFFFFF)' }
          },
          responsive: {
            rules: [{
              condition: { maxWidth: 500 },
              chartOptions: {
                legend: { layout: 'horizontal', align: 'center', verticalAlign: 'bottom' }
              }
            }]
          }
        });

        // Set up resize handler with debouncing
        if (!this._resizeHandler) {
          let resizeTimeout;
          this._resizeHandler = () => {
            if (resizeTimeout) {
              clearTimeout(resizeTimeout);
            }
            
            resizeTimeout = setTimeout(() => {
              if (this.chartInstance) {
                const container = this.$refs.chartcontainer;
                const containerWidth = container ? container.offsetWidth : 0;
                const containerHeight = container ? container.offsetHeight : 0;
                
                
                
                if (containerWidth > 0 && containerHeight > 0) {
                  
                  this.chartInstance.setSize(containerWidth, containerHeight, false);
                }
              }
            }, 300);
          };
          
          window.addEventListener('resize', this._resizeHandler);
          
        }
        
        // Initial one-time sizing with delay
        setTimeout(() => {
          if (this.chartInstance) {
            const container = this.$refs.chartcontainer;
            if (container && container.offsetWidth > 0 && container.offsetHeight > 0) {
              
              this.chartInstance.setSize(container.offsetWidth, container.offsetHeight, false);
            }
          }
        }, 250);

      } catch (error) {
        console.error(`RENDER: Error creating Highcharts instance #${this.chartId}:`, error);
        if (chartElement) {
          chartElement.innerHTML = `<div style='color: red; padding: 20px; text-align: center;'>Error rendering chart.</div>`;
        }
      }
    },

    _handlePortfolioChange() {
      
      this.loading = true;
      this.series = [];
      
      if(this.chartInstance) {
        this.chartInstance.update({
          series: [{ name: 'Loading...', data: [], color: '#555'}]
        }, true, false);
      }

      this._getPorfolioIndex()
        .then(() => {
          
        })
        .catch((error) => {
          console.error("CHANGE: Failed to fetch index data:", error);
          this.series = [];
        })
        .finally(() => {
          this.loading = false;
          this.$nextTick(() => {
            
            this._renderChart();
          });
        });
    },

    _closeWidget() {
      this.$emit("close-widget", {
        id: this.widget_name.id,
        name: this.widget_name.name
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

/* Loading Spinner Styles */
.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-background, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

/* Main Content Area Styling */
.portfolio-index-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: var(--color-surface, #20202B);
  position: relative;
}

/* Content Body - Applies Padding */
.content-body {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  gap: 12px;
  overflow: hidden;
  position: relative;
  height: 100%;

  @media (max-width: 767px) {
    padding: 12px 16px;
  }
}

/* Portfolio Selector Styling */
.portfolio-selector-container {
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.1));
  flex-shrink: 0;
  margin-bottom: 8px;

  .modern-select {
    width: 100%;
    padding: 10px 30px 10px 12px;
    border: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
    border-radius: 6px;
    background-color: var(--color-surface, #20202B);
    color: var(--color-text-primary, #FFFFFF);
    font-size: 14px;
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M2.5 4L6 7.5L9.5 4' stroke='%23BFC2CD' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 12px;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;

    &:focus {
      outline: none;
      border-color: var(--primary, #00aedd);
      box-shadow: 0 0 0 1px var(--primary, #00aedd);
    }

    &:hover {
      border-color: var(--primary, #00aedd);
    }

    option {
      background-color: var(--color-background, #0F0F1A);
      color: var(--color-text-primary, #FFFFFF);
      padding: 8px 12px;
    }

    option[disabled] {
      color: var(--color-text-secondary, #BFC2CD);
    }
  }
}

/* Chart Container Styling */
.chart-container {
  flex: 1;
  min-height: 200px;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

/* The actual Highcharts container element */
.chart-container > div {
  flex: 1;
  min-height: 0;
  width: 100%;
}

/* Deep selector for Highcharts SVG elements */
:deep(.highcharts-container),
:deep(.highcharts-root) {
  width: 100% !important;
  height: 100% !important;
}
</style>
