<template>
  <widget-wrapper
    title="Cambios en cartera"
    widget-id="widget-portfolio-changes"
    widget-name="Cambios de cartera"
    :has-help="true"
    :help-url="url_iframe"
    :help-title="help_tittle"
    :has-alternate-action="true"
    alternate-action-icon="launch"
    alternate-action-url="/app/portfolio"
    theme="dark"
    @close-widget="_closeWidget"
    @show-help="_sendDataToIframe"
  >
    <div v-if="loading" class="widget-loading">
      <div class="spinner"></div>
    </div>
    
    <div v-else class="portfolio-changes-content">
      <!-- Modern Portfolio Selector -->
      <div v-if="portfolio_list.length > 0" class="portfolio-selector">
        <div class="select-container">
          <select v-model="value_selected" @change="_selectPortfolio" class="custom-select">
            <option 
              v-for="portfolio in portfolio_list" 
              :key="portfolio.name"
              :value="portfolio.name"
            >
              {{ portfolio.value }}
            </option>
          </select>
          <span class="select-arrow material-icons">expand_more</span>
        </div>
      </div>
      
      <!-- Modern Table -->
      <div class="modern-table-container">
        <table class="modern-table">
          <thead>
            <tr>
              <th>{{ table_header[0] }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in table_body" :key="index">
              <td v-html="item.a"></td>
            </tr>
            <tr v-if="table_body.length === 0" class="empty-row">
              <td>No hay alertas disponibles para esta cartera</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import { mapGetters } from "vuex";
import PortfolioList from "@/views/configs/portfolio.json";
import APICaller from "@/components/codeComponents/APICaller.js";
import GetPortfolios from "@/components/codeComponents/getPortfolios.js";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";

export default {
  mixins: [APICaller, GetPortfolios],
  name: "PortfolioChanges",
  components: {
    WidgetWrapper
  },
  data() {
    return {
      portfolioJson: PortfolioList,
      portfolio_list: [],
      value_selected: "",
      table_header: ["Alarmas"],
      table_body: [],
      widget_name: {
        id: "widget-portfolio-changes",
        name: "Cambios de cartera",
      },
      loading: true,
      url_iframe: "https://player.vimeo.com/video/187302500?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre cambios en cartera",
    };
  },
  created() {
    this._loadData();
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _loadData() {
      this.loading = true;
      this._getPortfolios();
    },
    
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },

    _getPortfolioChanges() {
      this.loading = true;
      this.table_body = [];
      
      let success = (response) => {
        if (response.data.list != undefined) {
          let bestSectors = response.data.list["#items"];
          Object.entries(bestSectors).forEach((e) => {
            var obj = {};
            obj["a"] = e[1].data.message;
            this.table_body.push(obj);
          });
        }
        if (response.data.message != undefined) {
          let markup = response.data.message["#markup"];
          var obj1 = {};
          obj1["a"] = markup;
          this.table_body.push(obj1);
        }
        this.loading = false;
      };

      let failure = () => {
        this.loading = false;
      };

      let url = "/api/v1/portfolio/alerts/" + this.value_selected;

      if (this.value_selected && this.value_selected != "") {
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler(failure, "152", "Error recuperando alertas.");
        const apiCallConfig = { loadingOverlay: false };

        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      } else {
        this.loading = false;
      }
    },
    
    _getPortfolios() {
      let success = (response) => {
        if (response.data && response.data.length > 0) {
          this.portfolio_list = response.data.map(p => ({
            name: p.idc,
            value: p.name
          }));
          this._defaultPortfolio();
        } else {
          this.loading = false;
        }
      };

      let failure = () => {
        this.loading = false;
      };

      let url = "/api/v1/portfolio/list";
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(failure, "ERR-PORTFOLIO", "Error recuperando cartera.");
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _defaultPortfolio: function() {
      let success = (response) => {
        if (response.data && response.data.idc) {
          // Set the selected portfolio to the default from preferences
          this.value_selected = response.data.idc;
          this._getPortfolioChanges();
        } else if (this.portfolio_list.length > 0) {
          // If no default set, use the first portfolio
          this.value_selected = this.portfolio_list[0].name;
          this._getPortfolioChanges();
        } else {
          this.loading = false;
        }
      };

      let failure = () => {
        if (this.portfolio_list.length > 0) {
          this.value_selected = this.portfolio_list[0].name;
          this._getPortfolioChanges();
        } else {
          this.loading = false;
        }
      };

      let url = "/api/v1/portfolio-invest-preferences";
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(failure, "153", "Error recuperando preferencias de inversión.");
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _selectPortfolio() {
      this._getPortfolioChanges();
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-background, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

.portfolio-changes-content {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.portfolio-selector {
  .select-container {
    position: relative;
    
    .custom-select {
      width: 100%;
      padding: 10px 12px;
      border: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
      border-radius: 6px;
      background-color: var(--color-surface, #20202B);
      color: var(--color-text-primary, #FFFFFF);
      font-size: 14px;
      cursor: pointer;
      appearance: none;
      padding-right: 30px;
      transition: all 0.2s ease;
      
      &:focus {
        outline: none;
        border-color: var(--primary, #00aedd);
        box-shadow: 0 0 0 1px var(--primary, #00aedd);
      }
    }
    
    .select-arrow {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
      color: var(--color-text-secondary, #BFC2CD);
      font-size: 20px;
    }
  }
}

.modern-table-container {
  flex: 1;
  overflow-y: auto;
}

.modern-table {
  width: 100%;
  border-collapse: collapse;
  
  th {
    text-align: left;
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
    color: var(--color-text-secondary, #BFC2CD);
    border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
    position: sticky;
    top: 0;
    background-color: var(--color-surface, #20202B);
  }
  
  td {
    padding: 10px;
    font-size: 13px;
    color: var(--color-text-primary, #FFFFFF);
    border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.1));
    
    // For HTML content
    ::v-deep a {
      color: var(--primary, #00aedd);
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }
  
  tr {
    transition: background-color 0.15s ease;
    
    &:hover {
      background-color: var(--color-active-surface, #193141);
    }
    
    &:last-child td {
      border-bottom: none;
    }
  }
  
  .empty-row td {
    text-align: center;
    color: var(--color-text-secondary, #BFC2CD);
    font-style: italic;
    padding: 20px 10px;
  }
}
</style>
